<template>
  <div class="about">
    <div class="aboutTop">
      <img v-lazy="topBg" alt="" srcset="">
    </div>
    <div class="about-center">
      <div class="about-center-top">
        <p>公司简介</p>
      </div>
      <div class="about-center-center">
        <img v-lazy="img" alt="">
      </div>
      <div class="about-center-bottom">
        <img v-lazy="img1" alt="">
      </div>
    </div>

    <div class="about-culture">
      <div class="about-culture-top">
        <div class="title">企业文化</div>
        <p>千述科技以打造最具指向性的定制服务</p>
        <p>为宗旨，以成就客户、服务至上、创新</p>
        <p>合作、激情奋斗、诚信立业的价值观为</p>
        <p>基石，以专业技术帮助客户实现品牌落</p>
        <p>地，努力奋斗成为最优秀的互联网定制</p>
        <p>品牌。</p>
      </div>
      <div class="about-culture-bottom">
        <img :src="culture" alt="">
      </div>
    </div>

    <div class="about-bottom">
      <div class="about-bottom-top">
        <p>团队构成</p>
      </div>
      <div class="about-bottom-bottom">
        <div>
          <img v-lazy="team1" alt="">
          <img v-lazy="team2" alt="">
        </div>
        <img v-lazy="team3" alt="">
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      img: require('../../assets/Mi/about/img.png'),
      topBg: require('../../assets/Mi/about/topBg.png'),
      img1: require('../../assets/Mi/about/img1.png'),
      team1: require('../../assets/Mi/about/team1.png'),
      team2: require('../../assets/Mi/about/team2.png'),
      team3: require('../../assets/Mi/about/team3.png'),
      culture: require('../../assets/Mi/about/culture.png')
    }
  }
}
  </script>
  <style lang="scss" scoped>
.about {
  .aboutTop {
    width: 100%;
    height: 377px;
    img {
      width: 100%;
      height: 377px;
    }
  }
  .about-center {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-center-top {
      margin-bottom: 20px;
      p {
        color: #000000;
        font-size: 23px;
        font-weight: bold;
        letter-spacing: 0.5px;
      }
    }
    .about-center-center {
      img {
        width: 345px;
        height: 425px;
      }
    }
    .about-center-bottom {
      margin-top: 22px;
      img {
        width: 262px;
        height: 87px;
      }
    }
  }

  .about-culture {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .about-culture-top {
      .title {
        text-align: center;
        font-size: 23px;
        color: #000000;
        font-family: SourceHanSansCN-Bold;
        margin-bottom: 15px;
      }

      p {
        font-size: 14px;
        color: #9c9c9c;
        font-family: SourceHanSansCN-Regular;
        line-height: 25px;
        letter-spacing: 1px;
      }
    }

    .about-culture-bottom {
      img {
        width: 345px;
        height: 267px;
      }
    }
  }

  .about-bottom {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-bottom-top {
      margin-bottom: 11px;
      p {
        color: #000000;
        font-size: 23px;
        font-weight: bold;
      }
    }
    .about-bottom-bottom {
      div {
        margin-bottom: 8px;
        img:nth-child(1) {
            width: 162px;
          height: 103px;
          margin-right: 16px;
        }
        img:nth-child(2) {
            width: 167px;
          height: 113px;
        }
      }

      img {
        width: 167px;
          height: 114px;
      }
    }
  }
}
</style>